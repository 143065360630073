import R from "ramda";

const DIVIDE_MARK = "-";
const DEFAULT_PREFIX = "Simpleryo Finance Form";

const convertToAppList = list =>
  R.compose(
    R.reduce((acc, cur) => {
      const { name } = cur;
      const perfix = R.compose(
        R.head,
        R.split(DIVIDE_MARK),
        str =>
          str.indexOf(DIVIDE_MARK) > -1
            ? str
            : `${DEFAULT_PREFIX}${DIVIDE_MARK}${str}`
      )(name);
      return {
        ...acc,
        [perfix]: [...(acc[perfix] || []), cur]
      };
    }, {}),
    R.map(item => R.pick(["id", "name"], item))
  )(list);

export default convertToAppList;
