import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import R from "ramda";
import queryString from "query-string";
import { Button, Alert } from "@simpleryo/collinson-uikit";
import { APPLICATION } from "../../constants/routes";
import DataSource from "../Application/DataSource";

class Landing extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { applyName }
      },
      location: { state = {} }
    } = props;
    this.state = {
      applyName: state.applyName || applyName,
      isLoading: false,
      error: undefined,
      applyId: undefined
    };
  }

  createApp = () => {
    this.setState({ isLoading: true });
    DataSource.createApplication(this.state.applyName).then(
      res => {
        const { code, content, msg } = res;
        if (code === 0) {
          this.setState({ isLoading: false, applyId: content.applyId });
        } else {
          this.setState({
            isLoading: false,
            error: msg || "Create Applications Fail"
          });
        }
      },
      () => {
        this.setState({
          isLoading: false,
          error: "Create Applications Fail"
        });
      }
    );
  };

  render() {
    const {
      location: { search }
    } = this.props;
    const { isLoading, error, applyName, applyId } = this.state;
    if (applyId) {
      const hideHeader = R.equals(
        applyName.toLowerCase(),
        "ccfl-investordetails"
      );
      const appSearch = hideHeader
        ? R.compose(
            queryString.stringify,
            R.mergeDeepLeft({ hide_header: hideHeader }),
            queryString.parse
          )(search)
        : search;
      return (
        <Redirect
          to={`${APPLICATION}/${applyId}/1?${appSearch.substring(
            appSearch.indexOf("?") + 1
          )}`}
        />
      );
    }
    return (
      <div className="row">
        <div className="text-center margin-top-20">
          <h3>Application Form</h3>
        </div>
        <hr />

        <div className="text-center">
          <Button
            htmlType="button"
            onClick={this.createApp}
            loading={isLoading}
          >
            Apply Now
          </Button>
        </div>

        {error && (
          <div className="col-xs-12">
            <Alert showIcon type="error" message={error} />
          </div>
        )}

        <hr />
        <div className="margin-left-100">
          <h5>注册所需材料</h5>
          <h6>如果您是中国护照</h6>
          <ul>
            <li>中国护照+所在国签证</li>
            <li>
              （中国身份证正反面+中国手机号）或（中国身份证正反面+中国银行卡正面）或（中国身份证正反面+驾照）
            </li>
            <li>三个月内地址信证明</li>
          </ul>
          <h6>如果您是非中国护照</h6>
          <ul>
            <li>所在国护照</li>
            <li>驾照 或 枪证</li>
            <li>三月内地址信证明</li>
          </ul>
          <h6>如果您是公司注册用户</h6>
          <ul>
            <li>董事长的如上个人材料</li>
            <li>每位持股超过25% 股东的如上个人材料</li>
            <li>公司地址信</li>
          </ul>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Landing;
