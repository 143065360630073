import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import * as Routes from "../../constants/routes";
import logoIcon from "../../../assets/imgs/simpleryo.svg";

export default class Header extends Component {
  render() {
    const { className } = this.props;
    return (
      <header className={classNames("row header", className)}>
        <div className="header__container">
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string
};
