import React from "react";
import PropTypes from "prop-types";
import { ContentCard, SignaturePad, Markdown } from "@simpleryo/syw-uikit";
import { fetchStatusMap } from "../constants/status";

const SignaturePadWithHeader = ({ onSubmit, title, fetchStatus, error }) => (
  <div className="signature-pad container">
    <ContentCard size="middle" centerAlign>
      <p className="grey padding-bottom-60">{title}</p>
      <h2>Digital Signature 电子签名</h2>
      <Markdown
        text="Use the box below to enter your signature. If you’re using a touchscreen, you can sign directly. Otherwise, use the mouse on your computer.
                请在以下方框内签名，触屏设备可直接手签，电脑设备可用鼠标签字"
      />
    </ContentCard>
    <div className="row">
      <SignaturePad
        onSubmit={onSubmit}
        isSubmitting={fetchStatus === fetchStatusMap.LOADING}
        clearBtnTxt="Clean 重新签名"
        submitingBtnTxt="Submitting 提交中"
        submitBtnTxt="Submit 提交"
        waterMarkTxt=""
        error={error}
      />
    </div>
  </div>
);

SignaturePadWithHeader.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fetchStatus: PropTypes.string,
  error: PropTypes.string
};

export default SignaturePadWithHeader;
