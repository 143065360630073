const DEFAULT = "default";
const ACTIVE = "active";
const INACTIVE = "inactive";
const EDITING = "editing";
const COMPLETED = "completed";
const ERROR = "error";
const LOADING = "loading";
const LOADED = "loaded";
const CONDITION = "condition";
const HIDE = "hide";
const VERIFIED = "verified";
const VERIFYING = "verifying";
const INVALID = "invalid";
const VALID = "valid";
const EXPIRED = "expired";

export const tokenStatusMap = {
  INVALID,
  VALID,
  EXPIRED
};

export const stepStatusMap = {
  DEFAULT,
  COMPLETED,
  ERROR
};

export const cardStatusMap = {
  DEFAULT,
  EDITING,
  COMPLETED,
  ERROR
};

export const groupStatusMap = {
  ACTIVE,
  INACTIVE,
  CONDITION,
  HIDE
};

export const fetchStatusMap = {
  LOADING,
  LOADED,
  VERIFIED,
  VERIFYING
};

export default {
  stepStatusMap,
  groupStatusMap
};
