import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import R from "ramda";
import { Button, Spin, Alert } from "@simpleryo/collinson-uikit";
import { LANDING } from "../constants/routes";
import DataSource from "../components/Application/DataSource";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: undefined,
      list: [],
      data: {},
      applyId: undefined
    };
  }

  componentDidMount() {
    DataSource.getApplicationList().then(
      res => {
        const list = [];
        R.mapObjIndexed((val, category) => {
          list.push(category);
          return val;
        })(res);
        this.setState({ isLoading: false, data: res, list });
      },
      err => {
        console.log("retrieve application list fail", err);
      }
    );
  }

  render() {
    const { isLoading, list, data, error } = this.state;
    return (
      <div className="row">
        <Spin wrapperClassName="col-xs-12" spinning={isLoading}>
          {R.not(R.isEmpty(list)) &&
            R.map(category => (
              <Fragment key={category}>
                <hr />
                <h3>{category}</h3>
                <ul className="list-unstyled list-inline">
                  {R.map(app => {
                    const { name } = app;
                    return (
                      <li key={name}>
                        <Link
                          to={{
                            pathname: `${LANDING}/${name}`,
                            state: { applyName: name }
                          }}
                        >
                          <Button htmlType="button" size="small">
                            {name}
                          </Button>
                        </Link>
                      </li>
                    );
                  })(data[category])}
                </ul>
                <hr />
              </Fragment>
            ))(list)}
        </Spin>

        {error && (
          <div className="col-xs-12">
            <Alert showIcon type="error" message={error} />
          </div>
        )}
      </div>
    );
  }
}
