import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import R from "ramda";
import { ContentCard, Badge, Button } from "@simpleryo/collinson-uikit";

export default class DocumentsPage extends Component {
  render() {
    const {
      location: {
        search,
        state: { submit = {} }
      }
    } = this.props;
    const returnUrl = R.compose(
      R.path(["return_url"]),
      queryString.parse
    )(search);
    const { content = [], error, success } = submit;
    return (
      <div className="row">
        <div className="col-xs-12">
          <ContentCard centerAlign>
            {success && (
              <div>
                <Badge
                  size={80}
                  icon="icon-check"
                  customClass="white bg--green margin-top-60"
                />
                <p>{"SUCCESS"}</p>
              </div>
            )}
            {error && (
              <div>
                <Badge
                  size={80}
                  icon="icon-close"
                  customClass="white bg--red margin-top-60"
                />
                <p>{"ERROR"}</p>
              </div>
            )}
          </ContentCard>
        </div>
        <div className="col-xs-12">
          <hr />
        </div>
        {R.not(R.isEmpty(content)) &&
          !returnUrl && (
            <div className="col-xs-12">
              <ul>
                {R.addIndex(R.map)((item, index) => (
                  <li key={index} className="padding-bottom-10">
                    <a href={item} rel="noopener noreferrer" target="_blank">
                      {"Download Document"}
                    </a>
                  </li>
                ))(content)}
              </ul>
            </div>
          )}
        <div className="col-xs-12">
          <hr />
        </div>
        {returnUrl && (
          <div className="col-xs-12 text-center">
            <Button
              size="small"
              style={{
                backgroundColor: "#0193cf",
                borderColor: "#1484b3"
              }}
              type="primary"
              onClick={() => {
                window.location.href = returnUrl;
              }}
            >
              CONTINUE
            </Button>
          </div>
        )}
      </div>
    );
  }
}

DocumentsPage.propTypes = {
  location: PropTypes.object.isRequired
};
